import { mapGetters, mapMutations, mapActions } from "vuex"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, VALIDATION_ERROR, MAX_CHARACTER_LIMIT } from "@/constants"
import ThemisInput from "@/components/shared/input"

export default {
  name      : "IssueForms",
  components: {
    ThemisInput
  },
  data() {
    return {
      showAddIssueFormDialog     : false,
      newIssueFormName           : undefined,
      issueFormNameCharacterLimit: MAX_CHARACTER_LIMIT.ISSUE_FORM_NAME
    }
  },
  methods: {
    ...mapActions({
      addFormTemplate: "formTemplates/addFormTemplate",
      notify         : "shared/notify"
    }),
    ...mapMutations({
      setFormTemplateAddError: "formTemplates/setFormTemplateAddError"
    }),
    cancelNewIssueForm() {
      this.newIssueFormName       = undefined
      this.showAddIssueFormDialog = false
    }
  },
  computed: {
    ...mapGetters({
      formTemplates         : "formTemplates/formTemplates",
      isLoadingFormTemplates: "formTemplates/isLoadingFormTemplates",
      isFormTemplateAdded   : "formTemplates/isFormTemplateAdded",
      formTemplateAddError  : "formTemplates/formTemplateAddError",
      isAddingFormTemplate  : "formTemplates/isAddingFormTemplate"
    }),
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORMS, this.$t.bind(this))
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.ISSUE_FORMS, this.$t.bind(this))
    },
    isIssueFormNameDuplicate() {
      if(this.formTemplateAddError) {
        return this.formTemplateAddError.field === VALIDATION_ERROR.FIELD.NAME &&
          this.formTemplateAddError.type === VALIDATION_ERROR.TYPE.DUPLICATE
      }
    },
    isValidIssueFormName() {
      return this.newIssueFormName && !this.isIssueFormNameDuplicate &&
      this.newIssueFormName.length <= this.issueFormNameCharacterLimit
    }
  },
  watch: {
    newIssueFormName: {
      handler: function() {
        this.setFormTemplateAddError(undefined)
      }
    },
    isFormTemplateAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "972",
            parameters: {
              name: this.newIssueFormName
            }
          })
          this.newIssueFormName       = undefined
          this.showAddIssueFormDialog = false
        }
      }
    }
  }
}