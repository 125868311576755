export default {
  name: "Input",
  data: () => ({
    labelClass   : "",
    inputFocussed: false
  }),
  props: {
    pLabelClass    : String,
    pLabel         : String,
    pRules         : String,
    pName          : String,
    pShowSaveCancel: Boolean,
    pSaving        : Boolean
  },
  methods: {
    handleInputBlur() {
      this.inputFocussed = false
      this.labelClass    = "info--text"
    },
    handleInputFocus() {
      this.inputFocussed = true
      this.labelClass    = this.pLabelClass
    },
    getLabelClass(errors) {
      if (errors.length) {
        return "error--text text--darken-2"
      }
      if (this.inputFocussed) {
        return this.pLabelClass
      } else {
        return "info--text"
      }
    }
  },
  watch: {
    pLabelClass: {
      immediate: true,
      handler  : function(newValue) {
        if (this.inputFocussed) {
          this.labelClass = newValue
        } else {
          this.labelClass = "info--text"
        }
      }
    }
  }
}